function updateCallback() {
  // copy-paste from shift4shop core.js
  /* eslint-disable */
  typeof lozad != 'function' &&
    (jQuery('div.product-items').each(function () {
      var n = 0;
      jQuery('.product-item', this).each(function (t, i) {
        var r = jQuery(i).height();
        r > n && (n = r);
      });
      jQuery(this).attr('data-itemsHeight', n);
    }),
    jQuery('div.product-items').each(function (n, t) {
      var i = jQuery(t).attr('data-itemsHeight');
      i != undefined && i + '' != '' && jQuery(t).find('.product-item').height(i);
    }));

  var maxHeight = 0;
  jQuery('.product-items .product-item .name').each(function () {
    if (jQuery(this).height() > maxHeight) {
      maxHeight = jQuery(this).height();
    }
  });
  jQuery('.product-items .product-item .name').height(maxHeight);
  /* eslint-enable */
}

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 48,
  },
  product: {
    noImageSrc: 'https://www.industrialairpower.com/assets/images/default.jpg',
  },
  facets: {
    rangedFacet: { fields: ['Price'], name: 'priceFacet' },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchPageCategory',
      type: 'SearchPage',
      location: '.category-products',
      template: 'SearchPage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.site-header #searchBox',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
      },
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'facet-dialog',
      type: 'ContextDialog',
      template: 'Dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
